@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient {
  background: linear-gradient(30deg, #c3a5e2, white, transparent);
  height: 20rem;
  width: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.gradient-mob {
  background: linear-gradient(to top, rgba(175, 138, 212, 0.4), white, white, white);
  height: 10rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}